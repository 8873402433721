import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/Layout"
import Icon404 from "../data/icons/icon404.svg"

const Container = styled.div`
position:relative;
max-width:1200px;
width:90%;
margin:auto;
display:flex;
flex-flow:row wrap;
justify-content:space-evenly;
align-items:center;


&>*:first-child {
  margin:40px 0px;
  flex-basis:80%;
  order:2;
  max-height:300px;
}
&>*:last-child {
  flex-basis:100%;
  order:1;
  &>* {
    margin: 40px 0px;
  }
}


@media(min-width:993px) {
  &>*:first-child {
    flex-basis:42%;
    order:2;
  }
  &>*:last-child {
    flex-basis:42%;
    order:1;
  }
}
`
const Button = styled(Link)`
text-decoration:none;
color:white;
background-color:var(--tmain);
border: 2px solid var(--tmain);
transition: background-color 0.2s ease;
cursor:pointer;
padding:6px 20px;
border-radius:5px;
&:hover {
  background-color:rgba(0,0,0,0);
  color:var(--tmain);
}

`
const NotFoundPage = ({data}) => {
  return (
    <Container>
      <Icon404/>
      <div>
        <h1>404-Meldung</h1>
        <p>Die gewünschte Seite konnte nicht geladen werden. <br/> <br/>Sie können zu der vorherigen Seite zurückkehren oder über die darunterliegende Schaltfläche zur Startseite gelangen.</p>
        <Button to="/">Startseite</Button>
      </div>
      
    </Container>
  )
}

export default NotFoundPage
