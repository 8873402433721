import React from 'react'
import styled from 'styled-components'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link } from 'gatsby'

const Container = styled.div`
position:relative;
bottom:0;
width:100%;
display:flex;
flex-direction:column;
align-items:center;
padding:16px 16px;
color:var(--twhite);
background-color:var(--tmain-dark);
& div{
    margin:8px 0;
    font-size:16px;
    font-family:Open Sans;
    font-weight:300;
}
& >div:nth-child(1){
    display:flex;
    flex-direction:column;
    gap:14px;
}
& >div:nth-child(2){
    & a{
        text-decoration:none;
        color:var(--twhite);
    }
}
@media(min-width:993px){
    flex-direction:row;
    justify-content:space-between;
    & div{
        margin:0;
        font-size:18px;
    }
    & >div:nth-child(1){
        display:flex;
        flex-direction:row;
        gap:0;
    }
}
`

const FootLink = styled(AnchorLink)`
text-decoration:none;
color:var(--twhite);
margin:0 8px;
font-size:16px;
font-family:Open Sans;
&:hover{
    cursor:pointer;
}
@media(min-width:993px){
    font-size:18px;
}
`

const Footer = ({}) => (
    <Container>
        <div>
            <FootLink to={'/imprint/'}>Impressum</FootLink>
            <FootLink to={'/privacy/'}>Datenschutz</FootLink>
        </div>
        <div><Link to="https://kudlik-pfeifer.de/">Powered by Kudlik & Pfeifer</Link></div>
    </Container>
)

export default Footer