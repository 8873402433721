import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import BurgerMenu from '../data/icons/CleanBurgerMenu.svg'
import TemporaryLogo from '../data/icons/TemporaryLogoCorrected.svg'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Container = styled.div`
position:sticky;
top:0;
left:0;
width:100%;
background-color:var(--twhite);
z-index:80;
`

const Desktop = styled.div`
display:none;
position:relative;
top:0;
left:0;
width:100%;
height:85px;
flex-direction:row;
align-items:center;
justify-content:space-between;
-webkit-backface-visibility: hidden;
box-shadow: 0 -6px 20px 0px rgba(0,0,0,0.4);
@media(min-width:993px){
    display:flex;
}
`

const Mobile = styled.div`
position:relative;
top:0;
left:0;
width:100%;
height:60px;
display:flex;
flex-direction:row;
align-items:center;
justify-content:space-between;
-webkit-backface-visibility: hidden;
box-shadow: 0 -6px 20px 0px rgba(0,0,0,0.4);
& >svg{
    width:38px;
    height:38px;
    margin-right:5px;
    &:hover{
        cursor:pointer;
    }
}
@media(min-width:993px){
    display:none;
}
`

const Dropdown = styled.div`
position:fixed;
top:0;
left:0;
width:auto;
max-width:100%;
height:100vh;
padding:10px 24px 10px 12px;
transform:${props => props.isdropdown==1?('translateX(0%)'):('translateX(-100%)')};
transition:transform 1s ease, box-shadow 1s ease;
background-color:var(--tmain-dark);
color:var(--twhite);
box-shadow: ${props => props.isdropdown==1?('rgba(0, 0, 0, 0.22) 5px 0px 12px;'):('rgba(0, 0, 0, 0.0) 0px 15px 12px;')};
& h1{
    position:relative;
    font-family:Open Sans;
    font-size:20px;
    font-weight:400;
    & span{
        position:relative;
    
        &:after{
            position:absolute;
            content:'';
            bottom:-2px;
            left:0;
            width:100%;
            height:1.5px;
            background-color:var(--twhite);
        }
    }
}
@media(min-width:993px){
    display:none;
}
`

const ClosingDiv = styled.div`
position:fixed;
width:100%;
height:100vh;
display:${props => props.isdropdown==1?('block'):('none')};
z-index:-1;
@media(min-width:993px){
    display:none;
}
`

const CompanyName = styled.div`
position:relative;
display:flex;
flex-direction:row;
align-items:center;
margin-left:10px;
& >svg{
    width:38px;
    height:38px;
}
& h1{
    display:none;
    margin-left:10px;
    font-family:Open Sans;
    font-size:20px;
    font-weight:300;
}
& >a{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    &:hover{
        cursor:pointer;
    }
}
@media(min-width:351px){
    & h1{
        display:initial;
    }
}
@media(min-width:993px){
    & h1{
        font-size:24px;
    }
    & >svg{
        width:48px;
        height:48px;
    }
}
`

const Buttons = styled.div`
display:flex;
flex-direction:column;
& a{
    font-family:Open Sans;
    font-size:18px;
    font-weight:300;
    margin:4px 0;
    color:var(--twhite);
    text-decoration:none;
}
@media(min-width:993px){
    flex-direction:row;
    margin-right:40px;
    
    & a{
        position:relative;
        font-size:20px;
        margin:0 20px;
        color:var(--tblack);
    
        &:after{
            position:absolute;
            content:'';
            left:0;
            bottom:-15px;
            opacity:0;
            height:2px;
            width:100%;
            background-color:var(--tmain);
            border-radius:200px;
            transition:0.15s all ease-in;
        }
    
        &:hover {
            cursor:pointer;
    
            &:after{
                bottom:-5px;
                opacity:1;
            }
        }
    }
}
`

const Navbar = (props) => {
    const [isDropdown, setIsDropdown] = useState(false);

    return(
        <Container>
            <Mobile>
                <CompanyName>
                    <TemporaryLogo/>
                    <h1>Amber Conicor GmbH</h1>
                    <AnchorLink to="/#start"/>
                </CompanyName>
                <BurgerMenu onClick={() => setIsDropdown(!isDropdown)}/>
            </Mobile>
            <Dropdown isdropdown={isDropdown?(1):(0)} onClick={() => setIsDropdown(!isDropdown)}>
                <h1><span>Navigation</span></h1>
                <Buttons>
                    <AnchorLink to="/#ueberuns">Über Uns</AnchorLink>
                    <AnchorLink to="/#nebenkostenabrechnung">Nebenkostenabrechnung</AnchorLink>
                    <AnchorLink to="/#faq">FAQ</AnchorLink>
                    <AnchorLink to="/#kontakt">Kontakt</AnchorLink>
                </Buttons>
            </Dropdown>
            <ClosingDiv isdropdown={isDropdown?(1):(0)} onClick={() => setIsDropdown(!isDropdown)}/>
            <Desktop>
                <CompanyName>
                    <TemporaryLogo/>
                    <h1>Amber Conicor GmbH</h1>
                    <AnchorLink to="/#start"/>
                </CompanyName>
                <Buttons>
                    <AnchorLink to="/#ueberuns">Über Uns</AnchorLink>
                    <AnchorLink to="/#nebenkostenabrechnung">Nebenkostenabrechnung</AnchorLink>
                    <AnchorLink to="/#faq">FAQ</AnchorLink>
                    <AnchorLink to="/#kontakt">Kontakt</AnchorLink>
                </Buttons>
            </Desktop>
        </Container>
    );
};

Navbar.propTypes = {
}

export default Navbar;